.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: s;
    z-index: 9999;
}

.progressBar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    overflow: hidden;
    margin-bottom: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.progress {
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
}

.progressText {
    position: absolute;
    bottom: 40px;
    right: 40px;
    font-family: var(--main-font);
    font-size: 160px;
    color: #191919;
}

@media screen and (max-width:568px) {
    .progressText {
        font-size: 88px;
    }
}