* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  overflow-x: hidden;
}
:root {
  --main-font: "Anton", system-ui;
  --primary-color: #1935C4;
  --secondary-color: #111;
}

h1,h2,h3,h4,h5,h6{
  font-family: var(--main-font);
  font-weight: normal;
  color: inherit;
  text-transform: uppercase;
  line-height: 1;
}

a{
  color:inherit;
  text-decoration: none;
}
.description {
  font-family: var(--main-font);
  text-transform: uppercase;
  text-align: justify;
}

.content{
  padding: 80px;
}

@media screen and (max-width:1200px){
  .content{
    padding: 16px;
  }
}